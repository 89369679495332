import React from "react"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section/section"

const SectionImage = styled.div`
    ${tw`w-7/12 items-center mx-auto`}
`

const NotFoundPage = ({ data, location }) => {

  	return (
    	<Layout>
        	<SEO
            	title="404: Page Not found"
	            pathname={location.pathname}
    	    />
			<Hero title="404: Page Not found" description="" />
        	<Section>
 		       	<h2>Oh dear, the page was not found!</h2>
				<SectionImage>
                    <Img fluid={data.hero.childImageSharp.fluid} alt="Page Not Found" />
                </SectionImage>
 	       </Section>
	    </Layout>
	)
}

export default NotFoundPage

export const query=graphql`
    query {
        hero: file(relativePath: { eq: "mirage-bad-gateway-600.png" }) {
            childImageSharp {
                fluid(maxWidth: 1216 quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`